import React, {useEffect, useState} from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';

Font.register({
  family: 'Kalam',
  src: '/Fonts/Kalam-Regular.ttf'
})

Font.register({
  family: 'Kalam Bold',
  src: '/Fonts/Kalam-Bold.ttf'
})

Font.register({
  family: 'Hindi',
  src: '/Fonts/TiroDevanagariHindi-Regular.ttf'
});

Font.register({
  family: 'Hindi Bold',
  src: '/Fonts/TiroDevanagariHindi-Regular.ttf'
});

Font.register({
  family: 'Gujarati',
  src: '/Fonts/NotoSerifGujarati-Regular.ttf'
});

Font.register({
  family: 'Gujarati Bold',
  src: '/Fonts/NotoSerifGujarati-Bold.ttf'
});

Font.register({
  family: 'Bengali',
  src: '/Fonts/NotoSerifBengali-Regular.ttf'
});

Font.register({
  family: 'Bengali Bold',
  src: '/Fonts/NotoSerifBengali-Bold.ttf'
});

Font.register({
  family: 'Open Sans',
  src: '/Fonts/OpenSans_Condensed-Regular.ttf'
});

Font.register({
  family: 'Open Sans Bold',
  src: '/Fonts/OpenSans_Condensed-Bold.ttf'
});

Font.register({
  family: 'Open Sans Semi',
  src: '/Fonts/OpenSans_Condensed-SemiBold.ttf'
});

function getFontFamily(language) {
  switch (language) {
    case 'Hindi':
    case 'Marathi':
      return 'Hindi';
    case 'Gujarati':
      return 'Gujarati';
    case 'Bengali':
      return 'Bengali';
    default:
      return 'Open Sans'; // Default font family
  }
}

const styles = StyleSheet.create({
  page: {
    position: "relative",
    padding: '18 24', 
    boxSizing: 'border-box', 
    overflow: 'hidden'
  },
  background_content: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: -1,
  },
  score_ring: {
    position: 'absolute', top: '21', right: '42'
  },
  trait_footer: {
    position: 'absolute', 
    width: '100%', 
    bottom: '0', 
    display: 'flex', 
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  main_body: {
    position: 'relative', 
    width: '100%', 
    height: '100%', 
    border: '1 solid black'
  },
  trait_content: {
    padding: '12 18', 
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  image_container: {
    width: '30%',
    alignItems: 'center'
  },
  graph_image: {
    width: '100%',
    height: 'auto',
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeader: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableCellHeader: {
    margin: "auto",
    fontSize: 14,
    fontFamily: 'Open Sans Bold'
  },
  tableCell: {
    margin: "auto",
    fontSize: 12,
    fontFamily: 'Open Sans'
  },
});
  
function chunkArray(array, chunkSize) {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

const MyDocument = ({ data ,graphImages, baseColors, trait_names, language, name, className, roll, mainGraph}) => {
  // const { name, class: className, section, roll_number: roll } = data.student_details;
  const primaryColor = [124, 60, 196]
  const getBaseColor = (opacity) => `rgba(${primaryColor[0]}, ${primaryColor[1]}, ${primaryColor[2]}, ${opacity})`;
  // const section = data.student_details.section;
  // console.log(graphImages)
  return (
    <>
      <Document>
        {/* Cover Page */}
        <Page size={'A4'}>
          <View style={{height: '15%', width: '100%', backgroundColor: getBaseColor(1), alignItems: 'center', justifyContent: 'center'}}>
            <Text style={{color: 'white', fontSize: 48, textAlign: 'center', fontFamily: 'Open Sans Bold'}}>Personalized</Text>
            <Text style={{color: 'white', fontSize: 20, textAlign: 'center', fontFamily: 'Open Sans Bold'}}>Assessment Report</Text>
          </View>
          <View style={{height: '65%', width: '100%'}}>
            <Image src="/main.png" style={{width: '100%', height: '100%'}} />
          </View>
          <View style={{height: '20%', width: '100%', backgroundColor: getBaseColor(1), padding: '15 20', display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
            <Text style={{fontSize: '40', color: 'white', fontFamily: 'Open Sans Bold'}}>Maximise Your Learning Mastery</Text>
            {/* <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
              <View style={{height: 1, backgroundColor: 'white', width: 50}} />
              <Text style={{width: 20, textAlign: 'center', color: 'white'}}>X</Text>
              <View style={{height: 1, backgroundColor: 'white', width: 50}} />
            </View> */}
            <View style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '0 10'}}>
              <Image src="/assessli_w.png" alt="description" style={{ width: '150px'}} />
              <Text style={{ padding: '0 5px 0 0', display: 'flex', fontSize: '18', fontFamily: 'Open Sans Bold', color: 'white'}}>
                {name} | {className} | {roll}
              </Text>
            </View>
          </View>
        </Page>

        {/* Performance Page */}
        <Page size={'A4'} style={styles.page}>
          {/* Background */}
          <View style={styles.background_content} fixed>  
            <View style={styles.trait_footer}>
              <Image src="/assessli_b.png" alt="description" style={{ width: '150px', padding: '0px 0px 0px 50px'}} />
              <Text style={{ padding: '0 5px 0 0', display: 'flex', fontSize: '16pt', fontFamily: 'Open Sans' }}>
                {name} | {className} | {roll}
              </Text>
            </View>
          </View>

          <View style={styles.main_body}>
            <View style={{alignItems: 'center', padding: '15 0'}}>
              <Text style={{padding: '7.5 12', backgroundColor: getBaseColor(1), color: 'white', borderRadius: 7.5, fontSize: 16, fontFamily: 'Open Sans Bold', flexShrink: 0}}>Your PAT Score</Text>
            </View>
            
            <View style={{alignItems: 'center'}}>
              <Image src={mainGraph} style={{width: 580, height: 300, padding: '5 20', marginTop: 5, zIndex: -1}} />
            </View>
            
            <View style={{padding: '5 20'}}>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>Trait</Text>
                  </View>
                  <View style={{...styles.tableColHeader, backgroundColor: getBaseColor(0.7)}}>
                    <Text style={styles.tableCellHeader}>You</Text>
                  </View>
                  <View style={{...styles.tableColHeader, backgroundColor: getBaseColor(0.7)}}>
                    <Text style={styles.tableCellHeader}>Average</Text>
                  </View>
                  <View style={{...styles.tableColHeader, backgroundColor: getBaseColor(0.7)}}>
                    <Text style={styles.tableCellHeader}>Target</Text>
                  </View>
                </View>
                {trait_names.map((trait, index) => {
                  const getColor = (opacity) => `rgba(${baseColors[index][0]}, ${baseColors[index][1]}, ${baseColors[index][2]}, ${opacity})`;
                  return(
                  <View style={styles.tableRow} key={index}>
                    <View style={{...styles.tableCol, backgroundColor: getColor(0.5)}}>
                      <Text style={styles.tableCell}>{trait}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {data.trait_data[trait].score}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {data.trait_data[trait].average_score}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {data.trait_data[trait].target_score}
                      </Text>
                    </View>
                  </View>
                )})}
              </View>
            </View>
          </View>
        </Page>

        <Page size={'A4'}>
          <View style={{height: '100%', width: '100%', alignItems: 'center'}}>
            <Image src="/Academic traits.png" style={{width: '100%', height: '75%', marginTop: 10}} />
            <Image src="/assessli_b.png" alt="Assessli Logo" style={{width: 150, marginTop: 10}}/>
            <View style={{position: 'absolute', bottom: 0, width: '100%', backgroundColor: getBaseColor(1), padding: '10 20', alignItems:'center', textAlign: 'center'}}>
              <Text style={{color: 'white', fontSize: 10}}>
                  <Text style={{fontFamily: 'Open Sans Bold'}}>Copyright Notice: </Text>
                  <Text style={{fontFamily: 'Open Sans'}}>This report including its design, content, and backend process, is in copyright © 2023 by ONE OATH | ONE OATH'S PAT have Provisional Patent under IP act | All rights reserved. | No part of this report may be reproduced, stored in a retrieval system, transmitted in any form, or by any means, electronic, mechanical, photocopying, or otherwise, without the prior permission of ONE OATH EDUCATIONAL RESEARCH AND TECHNOLOGIES PVT LTD. IN</Text>
              </Text>
            </View>
          </View>
        </Page>     

        <Page size={'A4'}>
          <View style={{height: '100%', width: '100%'}}>
            <Image src="/Group of traits.png" style={{width: '100%', height: '100%'}} />
            <View style={{position: 'absolute', bottom: 0, width: '100%', backgroundColor: getBaseColor(1), padding: '10 20', alignItems:'center', textAlign: 'center'}}>
              <Text style={{color: 'white', fontSize: 10}}>
                  <Text style={{fontFamily: 'Open Sans Bold'}}>Copyright Notice: </Text>
                  <Text style={{fontFamily: 'Open Sans'}}>This report including its design, content, and backend process, is in copyright © 2023 by ONE OATH | ONE OATH'S PAT have Provisional Patent under IP act | All rights reserved. | No part of this report may be reproduced, stored in a retrieval system, transmitted in any form, or by any means, electronic, mechanical, photocopying, or otherwise, without the prior permission of ONE OATH EDUCATIONAL RESEARCH AND TECHNOLOGIES PVT LTD. IN</Text>
              </Text>
            </View>
          </View>
        </Page>     
        
        {/* Characteristics Page */}
        <Page size={'A4'} style={styles.page}>
          {/* Background */}
          <View style={styles.background_content} fixed>
            <Image src="/grade_ring.png" alt="grade_ring" style={{position: 'absolute', top: '100', right: '10', width: '130'}} />
            <View style={styles.trait_footer}>
              <Image src="/assessli_b.png" alt="description" style={{ width: '150px', padding: '0px 0px 0px 50px'}} />
              <Text style={{ padding: '0 5px 0 0', display: 'flex', fontSize: '16pt', fontFamily: 'Open Sans' }}>
                {name} | {className} | {roll}
              </Text>
            </View>
          </View>

          <View style={styles.main_body}>
            {/* Header */}
            <View style={{alignItems: 'flex-start', padding: '15 15'}}>
              <Text style={{padding: '7.5 12', backgroundColor: '#fcdc51', color: '#4eac54', borderRadius: 7.5, fontSize: 16, fontFamily: 'Open Sans Bold', flexShrink: 0}}>Your PAT Score</Text>
            </View>

            {/* Characteristics */}
            <View style={styles.trait_content}>
                <View style={{border: `1 solid #fcdc51`, width: '65%', borderRadius: '20px', display: 'flex', flexDirection: 'column', padding: '8 12', alignItems: 'center'}}>
                  <Text style={{fontFamily: 'Open Sans Bold', fontSize: "18", color: '#4eac54'}}>Characteristics</Text>
                  <Text style={{fontFamily: `${getFontFamily(language)}`, fontSize: '11',marginTop: 5}}>{data.category.characteristics}</Text>
                </View>
  
                <View style={styles.image_container}>
                  <Text style={{fontFamily: 'Open Sans Bold', fontSize: "80", color: '#4eac54'}}>{data.category.grade}</Text>
                </View>
              </View>

              <View style={{margin: '25 20', padding: '8 18', flexDirection: 'column', alignItems: 'center', border: '1px solid #fcdc51', borderRadius: '20px'}} >
                <Text style={{marginTop: 20, padding: '7.5 12', backgroundColor: '#fcdc51', color: '#4eac54', borderRadius: 7.5, fontSize: 16, fontFamily: 'Open Sans Bold', flexShrink: 0}}>Recommendations</Text>
                {
                  data.category.category_solutions.map((item, index) => (
                    <View key={index} style={{marginTop: 10, flexDirection: 'row', padding: '5 25', justifyContent: 'center', alignItems: 'center'}}>
                      <Image src={`/traits_icon/traits_icons-${String(index + 2).padStart(2, '0')}.png`} style={{width: 40, height: 40, marginRight: 10}} />
                      <View style={{flexDirection: 'column'}}>
                        <Text style={{fontFamily: `${getFontFamily(language)} Bold`, fontSize: 12 }}>
                          {item.split(':')[0]}
                        </Text>
                        <Text style={{fontFamily: `${getFontFamily(language)}`, fontSize: 12 }}>
                          {item.split(': ')[1]}
                        </Text>
                      </View>
                    </View>
                  ))
                }
              </View>
          </View>
        </Page>

        {/* Traits */}
        {trait_names.map((traitName, traitIndex) => {
          const trait = data.trait_data[traitName];
          const getColor = (opacity) => `rgba(${baseColors[traitIndex][0]}, ${baseColors[traitIndex][1]}, ${baseColors[traitIndex][2]}, ${opacity})`;
          return(
          <>
            <Page size="A4" style={styles.page} key={traitIndex}>
              {/* Background */}
              <View style={styles.background_content} fixed>
                <Image src="/topic_illustrations/Score Ring.png" alt="ring" style={{position:'absolute', top: 30, right: -5, width: 130}} />
                <View style={{position: 'absolute', bottom: '-70', right: '-80', width: '150', height: '150', borderRadius: '50%', backgroundColor: getColor(0.3)}}></View>
                <View style={styles.trait_footer}>
                  <Image src="/assessli_b.png" alt="description" style={{ width: '150px', padding: '0px 0px 0px 50px'}} />
                  <Text style={{ padding: '0 5px 0 0', display: 'flex', fontSize: '16pt', fontFamily: 'Open Sans' }}>
                    {name} | {className} | {roll}
                  </Text>
                </View>
              </View>
              <View style={styles.main_body}>
                {/* Trait Heading and Score */}
                <View style={styles.trait_content}>
                  <Text style={{padding: '7.5 12', backgroundColor: getColor(1), color: 'black', borderRadius: 7.5, fontSize: 16, fontFamily: 'Open Sans Bold', flexShrink: 0}}>{traitName}</Text>
                  <Text style={{display: 'flex', alignItems: 'center', padding: '0 27 0 0'}}>
                    <Text style={{ fontSize: '12pt', fontFamily: 'Open Sans Semi' }}>Your Score - </Text>
                    <Text>&nbsp;</Text>
                    <Text style={{ fontSize: '22pt', fontFamily: 'Open Sans Semi', color: 'red' }}>{trait.score}</Text>
                  </Text>
                </View>

                {/* Trait Meaning and Graph */}
                <View style={styles.trait_content}>
                  <View style={{border: `1 solid ${getColor(1)}`, width: '65%', borderRadius: '20px', display: 'flex', flexDirection: 'column', padding: '8 12', justifyContent: 'center'}}>
                    <Text style={{fontFamily: 'Open Sans Bold', fontSize: "12"}}>Meaning:</Text>
                    <Text style={{fontFamily: `${getFontFamily(language)}`, fontSize: '10'}}>{trait.meaning}</Text>
                    <Text style={{fontSize: '10'}}>&nbsp;</Text>
                    <Text style={{fontFamily: 'Open Sans Bold', fontSize: "12"}}>Example:</Text>
                    <Text style={{fontFamily: `${getFontFamily(language)}`, fontSize: '10'}}>{trait.example}</Text>
                  </View>
    
                  <View style={styles.image_container}>
                    {/* <Image style={styles.graph_image} src={`/graphs/image${traitIndex + 1}.png`} /> */}
                    <Image style={styles.graph_image} src={`data:image/png;base64,${graphImages[traitName]}`} />
                  </View>
                </View>

                <View style={[styles.trait_content, {paddingTop: '5', paddingBottom: '20'}]}>
                  <View style={styles.image_container}>
                    <Image src={`/topic_illustrations/${traitName.toLowerCase()}.png`} alt="logic" style={{height: 100}} />
                  </View>
                  <View style={{border: `1 solid ${getColor(1)}`, width: '65%', borderRadius: '20px', display: 'flex', flexDirection: 'column', padding: '8 12', justifyContent: 'center'}}>
                    <Text style={{fontFamily: 'Open Sans Bold', fontSize: "12"}}>Factors Affecting Traits:</Text>
                    {trait.factors_affecting_trait.map((factor, index) => (
                      <Text key={index} style={{fontFamily: `${getFontFamily(language)}`, fontSize: '10'}}>- {factor}</Text>
                    ))}
                  </View>
                </View>

                <View style={{padding: '8 18', display: 'flex', flexDirection: 'column'}} >
                  <Text style={{fontSize: '18pt', color: getColor(1), padding: '5px 0px', fontFamily: 'Open Sans Bold'}}>Recommendations:-</Text>
                  <Text style={{fontSize: '11pt', fontFamily: `${getFontFamily(language)} Bold`}}>{trait.statement}</Text>
                    {/* Solutions */}
                      {trait.solutions.slice(0, 3).map((solution, index) => (
                        <View key={index} style={{marginTop: '16'}}>
                          <Text style={{fontSize: '12'}}>
                            <Text style={{fontFamily: 'Open Sans Bold'}}>Solution {index + 1}:</Text>
                            <Text>&nbsp;</Text>
                            <Text style={{fontFamily: `${getFontFamily(language)}`}}>{solution.solution_headline}</Text>
                          </Text>
                          {solution.solution_bullet_points.map((step, stepIndex) => (
                            <Text key={stepIndex} style={{fontFamily: `${getFontFamily(language)}`, fontSize:'10', marginTop: '5'}}>
                              - {step}
                            </Text>
                          ))}
                        </View>
                      ))}
                  </View>
              </View>
            </Page>
            {trait.solutions.length > 6 && (
              <Page size="A4" style={styles.page} key={traitIndex}>
                {/* Background */}
                <View style={styles.background_content} fixed>
                    <View style={{position: 'absolute', bottom: '-70', right: '-80', width: '150', height: '150', borderRadius: '50%', backgroundColor: getColor(0.3)}}></View>
                    <View style={styles.trait_footer}>
                      <Image src="/assessli_b.png" alt="description" style={{ width: '150px', padding: '0px 0px 0px 50px'}} />
                      <Text style={{ padding: '0 5px 0 0', display: 'flex', fontSize: '16pt', fontFamily: 'Open Sans' }}>
                        {name} | {className} | {roll}
                      </Text>
                    </View>
                  </View>

                <View style={styles.main_body}>
                  <View style={{padding: '8 18', display: 'flex', flexDirection: 'column'}} wrap>
                    {/* Solutions */}
                    {trait.solutions.slice(3).map((solution, index) => (
                        <View key={index} style={{marginTop: '16'}}>
                          <Text style={{fontSize: '12'}}>
                            <Text style={{fontFamily: 'Open Sans Bold'}}>Solution {index + 4}:</Text>
                            <Text>&nbsp;</Text>
                            <Text style={{fontFamily: `${getFontFamily(language)}`}}>{solution.solution_headline}</Text>
                          </Text>
                          {solution.solution_bullet_points.map((step, stepIndex) => (
                            <Text key={stepIndex} style={{fontFamily: `${getFontFamily(language)}`, fontSize:'10', marginTop: '5'}}>
                              - {step}
                            </Text>
                          ))}
                        </View>
                      ))}
                  </View>
                </View>
              </Page>
            )}
          </>
        )})}

        {/* Subject Wise Recommendations */}
        {Object.entries(data.recommendations).map(([subject, topics], index) => {
          const topicChunks = chunkArray(Object.values(topics), 5);
          return topicChunks.map((chunk, chunkIndex) => (
            <Page size="A4" style={styles.page} key={`${index}-${chunkIndex}`}>
              <View style={styles.background_content} fixed>
                <Image src="/PIE Logo big.png" alt="logo" style={{position:'absolute', top: 25, right: -15, width: 130}} />
                <View style={{position: 'absolute', bottom: '-70', right: '-80', width: '150', height: '150', borderRadius: '50%', backgroundColor: getBaseColor(0.3)}}></View>
                <View style={styles.trait_footer}>
                  <Image src="/assessli_b.png" alt="description" style={{ width: '150px', padding: '0px 0px 0px 50px'}} />
                  <Text style={{ padding: '0 5px 0 0', display: 'flex', fontSize: '16pt', fontFamily: 'Open Sans' }}>
                    {name} | {className} | {roll}
                  </Text>
                </View>
              </View>

              <View style={styles.main_body}>
                <View style={{...styles.trait_content, justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
                  <Text style={{padding: '7.5 12', backgroundColor: getBaseColor(index + 1), color: 'white', borderRadius: 7.5, fontSize: 16, fontFamily: 'Open Sans Bold', flexShrink: 0}}>
                    Recommendations ({subject.charAt(0).toUpperCase() + subject.slice(1)})
                  </Text>
                </View>

                {/* Recommendations */}
                {chunk.map((topic, topicIndex) => (
                  <View style={{width: '100%', padding: '5 30'}} key={topicIndex}>
                    <Text style={{marginBottom: -10}}>
                      <Text style={{fontSize: 12, fontFamily: 'Open Sans Bold'}}>Topic: </Text>
                      <Text>&nbsp;</Text>
                      <Text style={{fontSize: 12, fontFamily: 'Open Sans'}}>{topic.topic}</Text>
                    </Text>
                    <Text>
                      <Text style={{fontSize: 10, fontFamily: 'Open Sans Semi'}}>Sub Topic: </Text>
                      <Text>&nbsp;</Text>
                      <Text style={{fontSize: 10, fontFamily: 'Open Sans'}}>{topic.subtopic.join(', ')}</Text>
                    </Text>
                    <Text style={{fontSize: 10, fontFamily: `${getFontFamily(language)}`, margin: "10 0"}}>
                      {topic.recommendation}
                    </Text>

                    <View style={{width: '100%', height: 1, backgroundColor: 'black', opacity: '0.5'}}></View>
                  </View>
                ))}
              </View>
            </Page>
          ));
        })}

        {/* Features Page */}
        <Page size={'A4'}>
          <View style={{height: '100%', width: '100%'}}>
            <View style={{position: 'absolute', top: 0, width: '100%', alignItems: 'center', height: '20%', paddingTop: 20}}>
              <Text style={{fontSize: 25, fontFamily: 'Open Sans Bold'}}>Unleash Your Brilliance</Text>
              <Text style={{fontSize: 15, fontFamily: 'Open Sans'}}>with</Text>
              <Image src="/assessli_b.png" alt="Assessli Logo" style={{width: 120, marginTop: 20}}/>
            </View>
            <Image src="/features 1.png" style={{width: '100%', height: '100%', zIndex: -1}} />
            <View style={{position: 'absolute', bottom: 0, width: '100%', backgroundColor: getBaseColor(1), padding: '10 20', alignItems:'center', textAlign: 'center'}}>
              <Text style={{color: 'white', fontSize: 10}}>
                  <Text style={{fontFamily: 'Open Sans Bold'}}>Copyright Notice: </Text>
                  <Text style={{fontFamily: 'Open Sans'}}>This report including its design, content, and backend process, is in copyright © 2023 by ONE OATH | ONE OATH'S PAT have Provisional Patent under IP act | All rights reserved. | No part of this report may be reproduced, stored in a retrieval system, transmitted in any form, or by any means, electronic, mechanical, photocopying, or otherwise, without the prior permission of ONE OATH EDUCATIONAL RESEARCH AND TECHNOLOGIES PVT LTD. IN</Text>
              </Text>
            </View>
          </View>
        </Page>   

        {/* Contact Page */}
        <Page size={'A4'}>
          <View style={{height: '100%', width: '100%', padding: '100 20', alignItems: 'center'}}>
            <Image src="/assessli_b.png" alt="Assessli Logo" style={{width: 120, marginBottom: 30}}/>
            <Text style={{fontSize: 12, fontFamily: 'Open Sans'}} >Help us Improve</Text>
            <Text style={{fontSize: 12, fontFamily: 'Open Sans'}} >Share your feedback with us</Text>
            <Image src="/qr-01.png" alt="QR" style={{width: 170}}/>
            <View style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0 40', marginTop: 50}}>
              <View style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 180}}>
                <Image src="/bangalore.png" alt="Bangalore" style={{width: 150, height: 150, marginBottom: 15}}/>
                <Text style={{fontSize: 14, fontFamily: 'Open Sans Bold', color: 'red'}} >BANGALORE</Text>
                <Text style={{fontSize: 10, fontFamily: 'Open Sans'}} >Manjunatha layout, Near pragati High school, Munnekollal, Marathahalli, Bengaluru, Karnataka, 560037</Text>
              </View>
              <View style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 180}}>
                <Image src="/kolkata.png" alt="Kolkata" style={{width: 150, height: 150, marginBottom: 15}}/>
                <Text style={{fontSize: 14, fontFamily: 'Open Sans Bold', color: 'red'}} >KOLKATA</Text>
                <Text style={{fontSize: 10, fontFamily: 'Open Sans'}} >330, KC Dutta road Near Ovoy Ashram, Chakdaha, West Bengal, 741222</Text>
              </View>
            </View>
            
          </View>
          <View style={{position: 'absolute', bottom: 0, width: '100%', backgroundColor: getBaseColor(1), padding: '10 20', alignItems:'center', justifyContent: 'space-between', display: 'flex', flexDirection: 'row'}}>
              <View style={{display: 'flex', flexDirection: 'row'}}>
                  <View style={{width: 20, height: 20, borderRadius: 5, backgroundColor: 'white'}} >
                    <Image src="/whatups.png" alt="WhatsApp" style={{width: 20, height: 20}}/>
                  </View>
                  <Text style={{fontSize: 12, fontFamily: 'Kalam Bold', color: 'white', marginLeft: 10}}>8637356500</Text>
              </View>
              <View style={{display: 'flex', flexDirection: 'row'}}>
                  <View style={{width: 20, height: 20, borderRadius: 5, backgroundColor: 'white'}} >
                    <Image src="/google-mail-new.png" alt="GMail" style={{width: 20, height: 20}}/>
                  </View>
                  <Text style={{fontSize: 12, fontFamily: 'Kalam Bold', color: 'white', marginLeft: 10}}>info@oneoath.in</Text>
              </View>
              <View style={{display: 'flex', flexDirection: 'row'}}>
                  <View style={{width: 20, height: 20, borderRadius: 5, backgroundColor: 'white'}} >
                    <Image src="/linkedin.png" alt="LinkedIn" style={{width: 20, height: 20}}/>
                  </View>
                  <Text style={{fontSize: 12, fontFamily: 'Kalam Bold', color: 'white', marginLeft: 10}}>One Oath</Text>
              </View>
              <View style={{display: 'flex', flexDirection: 'row'}}>
                  <View style={{width: 20, height: 20, borderRadius: 5, backgroundColor: 'white'}} >
                    <Image src="/facebook.png" alt="Facebook" style={{width: 20, height: 20}}/>
                  </View>
                  <Text style={{fontSize: 12, fontFamily: 'Kalam Bold', color: 'white', marginLeft: 10}}>One Oath</Text>
              </View>
              <View style={{display: 'flex', flexDirection: 'row'}}>
                  <View style={{width: 20, height: 20, borderRadius: 5, backgroundColor: 'white'}} >
                    <Image src="/browser.png" alt="Browser" style={{width: 20, height: 20}}/>
                  </View>
                  <Text style={{fontSize: 12, fontFamily: 'Kalam Bold', color: 'white', marginLeft: 10}}>oneoath.in</Text>
              </View>
          </View>
        </Page>  
      </Document>
    </>
    
  )
};

export default MyDocument;