import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  colorClassMap,
  filterOptions,
  filterOptions2,
  countFilterValues,
} from "../../utils/commonHelper";

const FilterDropdown = ({
  filterStatus,
  setFilterStatus,
  allAnswersArr,
  allQuestions,
}) => {
  const handleItemClick = (key) => {
    if (filterStatus.includes(key)) {
      setFilterStatus(filterStatus.filter((item) => item !== key));
    } else {
      setFilterStatus([...filterStatus, key]);
    }
  };

  return (
    <div className="info_section">
      {Object.entries(filterOptions2).map(([key, value]) => (
        <div
          className="inline_filter"
          key={key}
          onClick={() => handleItemClick(key)}
          style={{
            // border: filterStatus.includes(key) ? "1px solid red" : "",
            // margin: "10px",
            cursor: "pointer",
          }}
        >
          <div className="left_side">
            <strong
              style={{
                // border: "1px solid red",
                padding: "2px 5px",
                borderRadius: "4px",
                marginRight: "5px",
                background: filterStatus.includes(key) ? "#5E16DA" : "#e6e6e6",
                color: "white",
              }}
            >
              <FontAwesomeIcon icon={faCheck} />
            </strong>
            <span className={colorClassMap[key] + " color_span"}></span>
            <p>{key}</p>
          </div>

          <div
            // style={{ border: "1px solid red" }}
            // onClick={() =>
            //   console.log({ allAnswersArr, value, key, allQuestions }, " all")
            // }
          >
            <strong className="filter_count">
              {countFilterValues(allAnswersArr, key, allQuestions)}
            </strong>
          </div>
        </div>
      ))}
    </div>

    // <div className="py-2 mx-3">
    //   <ul className="filter_dropdown_container">
    //     {Object.entries(filterOptions2).map(([key, value]) => (
    //       <li
    //         key={key}
    //         onClick={() => handleItemClick(key)}
    //         style={{
    //           border: filterStatus.includes(key) ? "1px solid red" : "",
    //           margin: "10px",
    //         }}
    //       >
    //         {key}
    //       </li>
    //     ))}
    //   </ul>
    // </div>
  );
};

export default FilterDropdown;
