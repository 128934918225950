import React, { useEffect, useState, useMemo } from 'react';
import { pdf } from '@react-pdf/renderer';
import MyDocument from './MyDocument';
import { useSelector } from 'react-redux';
import MainChartComponent from './MainChartComponent';

export const DownloadReportButton = () => {
  const [traitsData, setTraitsData] = useState(null);
  const [graphImages, setGraphImages] = useState({});
  const [isDataReady, setIsDataReady] = useState(false);
  const [mainGraph, setMainGraph] = useState(null); 
  const { userDetails } = useSelector((state) => state.authReducer);
  
  const baseColors = useMemo(() => {
    return [[240, 100, 188], [27, 123, 235], [243, 187, 67], [91, 195, 99], [234, 92, 52], [243, 187, 124], [251, 219, 92], [203, 108, 227], [243, 187, 124], [100, 203, 250], [235, 60, 52], [83, 107, 243]];
  }, []);
  
  const trait_names = useMemo(() => {
    return ["LOGIC", "CONCENTRATION", "FOCUS", "COGNITIVE SKILL", "RETENTION POWER", "HARD WORKING", "STUDY HABIT", "CONSCIOUSNESS", "SILLY MISTAKES", "KNOWLEDGE GAP", "IMPULSIVE", "LEARNING GAP"];
  }, []);
  
  const name = userDetails.fullName;
  let className = userDetails.classID;
  className = className.replace('_', ' ').replace(/^./, str => str.toUpperCase());
  const assessment_id = `assessment_${userDetails.classID.split('_')[1]}`;
  const roll = userDetails.rollNumber;
  const [reportStatus, setReportStatus] = useState('Generate Report');

  const handleCaptureMainGraph = (imgData) => {
    setMainGraph(imgData);
  }

  const fetchGraphImage = async (traitName, score) => {
    // Ensure the score has a decimal place
    if (Number.isInteger(score)) {
      score = `${score}.0`;
    } else {
      score = parseFloat(score).toFixed(1);
    }
  
    const response = await fetch(`https://prod-board-api-server-318620872792.us-west1.run.app/download-graphs/${assessment_id}/${traitName}/${score}`);
    if (response.ok) {
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          // Extract the Base64 string from the Data URL
          const base64String = reader.result.split(',')[1];
          resolve(base64String);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } else {
      console.error(`Failed to fetch image for ${traitName} with score ${score}`);
      return null;
    }
  };
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://prod-board-api-server-318620872792.us-west1.run.app/get-results/${userDetails.schoolName}/${userDetails.board}/${userDetails.stream}/${userDetails.difficulty}/${assessment_id}/Section_${userDetails.section}/${userDetails.fullName}_${userDetails.rollNumber}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setTraitsData(data);
      } catch (error) {
        console.error('There was a problem with your fetch operation:', error);
      }
    };

    const intervalId = setInterval(() => {
      if (!traitsData) {
        fetchData();
      } else {
        clearInterval(intervalId);
      }
    }, 10000); // 10 seconds interval

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [traitsData]);

  useEffect(() => {
    if (traitsData) {
      const fetchImages = async () => {
        const fetchedImages = {};
        await Promise.all(trait_names.map(async (traitName) => {
          const score = traitsData.trait_data[traitName].score;
          const imageSrc = await fetchGraphImage(traitName, score);
          if (imageSrc) {
            fetchedImages[traitName] = imageSrc;
          }
        }));

        setGraphImages(fetchedImages);

        if (Object.keys(fetchedImages).length === trait_names.length) {
          setIsDataReady(true);
        }
      };

      fetchImages();
    }
  }, [traitsData, trait_names]);

  const handleDownloadClick = async () => {
    setReportStatus('Generating...');
    const doc = (
      <MyDocument 
        data={traitsData} 
        language={"Hindi"} 
        baseColors={baseColors} 
        trait_names={trait_names} 
        name={name} 
        className={className} 
        roll={roll}
        graphImages={graphImages} 
        mainGraph={mainGraph}
      />
    );
    const asPdf = pdf([]); 
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();

    const formData = new FormData();
    formData.append('file', blob, `${userDetails.fullName}_${userDetails.rollNumber}.pdf`);
    formData.append('userEmail', userDetails.email); // Ensure these fields are correct
    formData.append('userName', userDetails.fullName);
    formData.append('schoolName', userDetails.schoolName);
    formData.append('assessmentID', assessment_id);

    // try {
    //   // Send the PDF to the backend API for email
    //   const response = await fetch('https://api-server-v2-318620872792.us-west1.run.app/send-email', {
    //     method: 'POST',
    //     body: formData
    //   });

    //   if (response.ok) {
    //     console.log('Email Sent Successfully!');
    //   } else {
    //     console.log('Error Sending Email');
    //   }
    // } catch (error) {
    //   console.error('Error:', error);
    //   console.log('Error Sending Email');
    //   console.log(error);
    // }

    try {
      // console.log("Attempting upload with formData:", formData);
      const response = await fetch(`https://prod-report-pdf-api-server-318620872792.us-west1.run.app/upload-report/${userDetails.schoolName}/${userDetails.fullName}/${assessment_id}`, {
        method: 'POST',
        body: formData,
      });
  
      if (response.ok) {
        console.log('Report PDF uploaded successfully!');
      } else {
        const errorText = await response.text(); // Log server error message
        // console.log('Error Uploading File:', errorText);
      }
    } catch (error) {
      // console.error('Error:', error);
      // console.log('Error Uploading File');
    }
  
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${userDetails.fullName}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    setReportStatus('Report Downloaded!');
  };
  

  if (!isDataReady) {
    return (
      <button
        disabled={true}
        style={{ backgroundColor: '#0A4B4B', color: 'white', padding: '10px 20px', fontSize: 16 }}
      >
        Please Wait, Analysing Your Responses...
      </button>
    );
  }

  return (
    <>
      <MainChartComponent onCapture={handleCaptureMainGraph} data={traitsData} trait_names={trait_names} />
      <button 
        onClick={handleDownloadClick}
        type={'button'}
        style={{ backgroundColor: 'teal', color: 'white', padding: '10px 20px', fontSize: 16 }}
      >
        {reportStatus}
      </button>
    </>
  );
};